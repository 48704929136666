@font-face {
  font-family: Archivo;
  src: url(./../../assets/fonts/Archivo-Italic-VariableFont.ttf);
}
@font-face {
  font-family: Bullate;
  src: url(./../../assets/fonts/Bullate-Regular.ttf);
}

* {
  font-size: 14px;
  font-weight: 700 !important;
}
