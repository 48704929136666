.tableSmall__container {
  flex: 48%;
  margin: 5px;
  min-width: 515px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 1px 11px 1px #9e9e9e;
  @media only screen and (max-width: 1180px) {
    min-width: 30rem;
  }
  @media only screen and (max-width: 900px) {
    min-width: 52rem;
  }
  @media only screen and (max-width: 768px) {
    min-width: 47rem;
  }
  @media only screen and (max-width: 600px) {
    min-width: 30rem;
  }
  @media only screen and (max-width: 420px) {
    min-width: 24.6rem;
  }
}

.tableSmall__title {
  border-radius: 10px 10px 10px 10px;
  background-color: $yellow;
}

.tableSmall table {
  border-collapse: collapse;
  width: 100%;
}

td,
th .tableSmall {
  //border: 1px solid black;
  padding: 8px;
}

tr.tableSmall.even {
  cursor: pointer;
  background-color: $white;
}

tr.tableSmall.odd {
  cursor: pointer;
  background-color: #fdf3d3;
}

.tableSmall__icon-camera {
  cursor: pointer;
  //position: absolute;
  //margin-top: 3px;
  font-size: 15px;
  // margin-left: 6px;
  color: $blue;
}

.tableSmall__td-icon-camera {
  padding-bottom: 5px;
  padding-right: 0px;
  padding-left: 5px;
}

.tableSmall__icon-arrow {
  cursor: pointer;
  position: absolute;
  margin-top: 3px;
  font-size: 16px;
  margin-left: 6px;
  color: $blue;
}

.margin-camera{
  margin-left: 10px;
}