@import "./../commonStyles/variables.scss";

.login__portada {
  background-image: url("../../assets/logos/login-portada.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.login__center-div {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

}
.register__center-div {
  top: 30%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 768px) {
    display: flex;
    top: 0;
    left: 0;
    transform: none;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (min-width: 800px) and (min-height: 700px) {
    transform: translate(-50%, -35%) scale(0.78);
  }
}

.login__formBox {
  margin-top: 150px;
  display: inline-block;
  border-radius: 20px;
  border: solid 2px $yellow;
  box-shadow: 0 10px 13px -7px #000;
  background-color: #fff;
  padding: 30px 30px 10px;
  @media only screen and (max-width: 768px) {
    margin: 2rem auto;
  }
}
.register__formBox {
  //margin-top: 150px;
  // display: inline-block;
  border-radius: 20px;
  border: solid 2px $yellow;
  box-shadow: 0 10px 13px -7px #000;
  background-color: #fff;
  // padding: 30px 30px 10px;
  @media only screen and (max-width: 768px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.register__container {
  margin-top: 100px;
  display: block;
  text-align: center;
  height: 100%;
}

.login__arrow-icon {
  background-color: $yellow !important;
}
.login__input {
  height: 1rem;
  width: 20rem;
}

.login__arrow-icon {
  color: $white;
  z-index: 0;
  :hover {
    background-color: $yellow;
    opacity: 0.5;
  }
}

.login__anchor {
  color: $black;
  text-decoration: underline;
}


.formBox{
    
  margin-top: 1em !important;
  display: block;
  text-align: center;
  height: 100%;
  .loginForm{ 

      margin-top: 150px;
      display: inline-block;
      border-radius: 20px; 
      box-shadow: 0px 10px 13px -7px $black;
      background-color: white;
      padding: 145px 30px 10px 30px;

      .loginFormImage{
          width: 200px; 
          margin-top: -147px;
          margin-bottom: -57px;
          margin-left: -19px;
      }

      .firstCustomInputBox{
          margin-top: 30%;
      }

      .verified{
          color: $green;
          font-size: 50px;
      }

      a{
          color:$black;
          text-decoration: underline;
      }

      h3{
          margin-top: 30px;
          margin-bottom: 30px;
      }

      .customInputBox{
          
          small{
              color: red;
          }
          .customLabel{
              width: auto !important;
              
          }
      }
  }
}

