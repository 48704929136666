@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
// Pages
@import "./pages/home.scss";
@import "./pages/help.scss";
@import "./pages/myAccount.scss";
@import "./pages/auth.scss";
@import "./pages/footer.scss";
@import "./pages/funkoDetail.scss";
@import "./components/funkoCard.scss";
@import "./components/health.scss";
@import "./components/tableSmall.scss";
@import "./components/customMenu.scss";
@import "./components/customTable.scss";
@import "./components/contentWrapper.scss";
@import "./commonStyles/fonts.scss";
@import "./commonStyles/variables.scss";// Asegúrate de importar el archivo CSS correcto para el widget

body {
  color: #263238;
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
}

.layout-container {
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
  }
}


/* Estilo para hacer que la tabla sea responsiva */
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

.table-container th {
  min-width: 150px;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .table-container .hide-mobile-thead{
    display: none;
  }
  .table-container th,
  .table-container td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    //padding: 8px;
  }

  .table-container th:before,
  .table-container td:before {
    content: attr(data-label);
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    //spadding-bottom: 4px;
    //margin-bottom: 4px;
  }

  .table-container tr {
    display: grid;
    grid-template-columns: 1fr;
    //gap: 12px;
   // padding-bottom: 12px;
    //margin-bottom: 12px;
  }

}

