.funkoCard__card {
  cursor: pointer;
  border: 4px solid $yellow;
  border-radius: 2rem;
  position: relative;
  width: 325px;
  height: 500px;
  margin: 20px;
  
}
.funkoCard__card-ads-mobile {
  cursor: pointer;
  border: 4px solid $yellow;
  border-radius: 2rem;
  position: relative;
  width: 160px;
  height: 324px;
  margin: 0px;
  .funkoCard__logo-top-left {
    position: absolute;
    top: 15px;
    left: 10px;
    background-color: #022342 !important;
    font-size: .8em;
    color: $white;
  }
  .funkoCard__logo-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 2rem;
    width: 2rem;
  }
  .funkoCard__title {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 53%;
    text-align: center;
    
    h5{
      font-size: 1em;
    }
  }
  
  .funkoCard__centered {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 53%;
  }
  
  .funkoCard__bottom-row {
    position: absolute;
    bottom: 5px;
    left: 10px;
    right: 10px;
    //display: flex;
    justify-content: space-between;
  }
  
  .funkoCard__center-col {
    width: 100%;
    height: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }
  .funkoCard__serie {
    width: 100%;
    background-color: $yellow;
    font-size: .8em;
    margin-bottom: -3px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .funkoCard__license {
    width: 100%;
    // background-color: #022342 !important;
    background-color: #0472bf !important;
    margin: 3% 0%;
    font-size: .8em;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $white;
  }
  
  .funkoCard__edition {
    width: 100%;
    background-color: #17c2c0!important;
    font-size: .8em;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .funkoCard__arrow-icon {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 1.2em;
    margin-right: 5px;
    color: $green;
  }
  .funkoCard__price {
    font-size: 1.5rem;
  }
  
  .funkoCard__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
}

.funkoCard__logo-top-left {
  position: absolute;
  top: 25px;
  left: 10px;
  background-color: #022342 !important;
  font-size: 1.3em;
  color: $white;
}

.funkoCard__logo-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 4rem;
  width: 4rem;
}
.funkoCard__title {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  height: 53%;
  text-align: center;
}

.funkoCard__centered {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  height: 53%;
}

.funkoCard__bottom-row {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  //display: flex;
  justify-content: space-between;
}

.funkoCard__center-col {
  width: 100%;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.funkoCard__serie {
  width: 100%;
  background-color: $yellow;
  font-size: 1.3em;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.funkoCard__license {
  width: 100%;
  // background-color: #022342 !important;
  background-color: #0472bf !important;
  margin: 3% 0%;
  font-size: 1.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $white;
}

.funkoCard__edition {
  width: 100%;
  background-color: #17c2c0!important;
  font-size: 1.3em;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
}

.funkoCard__arrow-icon {
  position: absolute;
  left: 70px;
  bottom: 10px;
  font-size: 2rem;
  margin-right: 5px;
  color: $green;
}
.funkoCard__price {
  font-size: 2rem;
}

.funkoCard__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}