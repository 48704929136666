.custom-table {
  width: 100%;
  overflow-x: auto;
}

.custom-table td,
.custom-table th {
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .custom-table tbody {
    :hover {
      background: none !important;
    }
  }
  .custom-table td {
    font-size: 12px;
    padding: 8px 5px;
  }
  .custom-table th {
    font-size: 12px;
    padding: 7px;
  }
  .custom-table th:nth-child(3),
  .custom-table td:nth-child(3),
  .custom-table th:nth-child(4),
  .custom-table td:nth-child(4),
  .custom-table td:nth-child(7) {
    display: none;
  }

  .custom-table td:nth-child(5) {
    text-align: center;
  }
  .custom_table-buttonCart {
    min-width: 10px !important;
    margin-left: -15px !important;
  }
}

.custom-table__price {
  background-color: #a5d2ac;
  padding: 5px;
  border-radius: 6px;
  color: #567d79;
}
