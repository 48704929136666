.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $yellow;
  color: #fff;
  padding: 16px;
  font-size: 14px;
}

.footer__block {
  flex-basis: 25%;
  text-align: center;
}

.footer__anchor{
    color: $black;
}
.footer ul{
    list-style:none
}

.footer__icons-social{
    color: $black;
    font-size: 2.5rem;
    margin: .4rem;
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-wrap: wrap;
  }

  .footer__block {
    flex-basis: 50%;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .footer__block {
    flex-basis: 100%;
  }
}
