.contentWrapper {
  max-width: 1800px;
  margin: 0px auto;
  min-height: 100vh;
  @media only screen and (max-width: 768px) {
    max-width: none;
    margin: none;
    min-height: none;
  }
}
