

.help__tabpanel-container{
    border: solid 3px $yellow;
    border-radius: 25px;
    margin-left: 1rem;
    //height: 100%;
    width: 100%;
    @media only screen and (max-width: 600px) {
        margin-left: 0;
      }
}

.help__tabpanel-tab{
    border: solid 3px $yellow !important;
    border-radius: 10px !important;
    margin: 5px !important;
    :focus{
        outline-color: $yellow !important;
    }
}