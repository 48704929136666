@import "./../commonStyles/variables.scss";

.funkoDetail__container {
  border: 3px solid $yellow;
  border-radius: 25px 25px 25px 25px;
  height: 35rem;
  min-width: 25rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

  display: flex;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
    margin-left: 0;
    align-content: center;
    height: 21rem;
  }
}
.funkoDetail__container-information {
  border: 3px solid $yellow;
  border-radius: 25px 25px 25px 25px;
  height: 35rem;
  min-width: 25rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

  display: flex;
  padding-top: 25px;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 2rem;
    margin-left: 0;
  }
}
.funkoDetail__container-table {
  border: 3px solid $yellow;
  padding: 0;
  border-radius: 25px 25px 25px 25px;
  //height: 33rem;
  min-width: 25rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

  display: flex;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
    margin-left: 0;
  }
}

.funkoDetail__img {
  margin-top: 4rem;
  object-fit: contain;
  height: 20rem;
  max-width: 25rem;
  @media only screen and (max-width: 768px) {
    height: 15rem;
    max-width: 20rem;
    margin: 0 auto;
  }
}

.funkoDetail__social-whatsapp-container {
  background-color: #29c031;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  @media only screen and (max-width: 768px) {
    margin: 5px;
    height: 2.5rem;
  }
}

.funkoDetail__social-whatsapp-container a {
  color: $white;
}

.funkoDetail__social-facebook-container {
  background-color: #324b81;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  @media only screen and (max-width: 768px) {
    margin: 5px;
    height: 2.5rem;
  }
}

.funkoDetail__social-facebook-container a {
  color: $white;
}

.funkoDetail__social-mail-container {
  background-color: #c54242;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  @media only screen and (max-width: 768px) {
    margin: 5px;
    height: 2.5rem;
  }
}

.funkoDetail__social-mail-container a {
  color: $white;
}

.funkoInformation {
  //margin-right: -1%;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    margin-top: 1.5rem;
  }
}
.generalInfo {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.funkoInformation .generalInfo .number {
  background: #f6b036;
  padding: 6px;
  border-radius: 5px;
  color: #fff;
  margin-left: 4px;
}

.funkoInformation .generalInfo .licence {
  background-color: #17c2c0;
  padding: 6px;
  border-radius: 5px;
  color: #fff;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.funkoInformation .generalInfo .serie {
  border: solid 0px;
  background: #3692f5;
  padding: 6px;
  border-radius: 5px;
  color: #fff;
  margin-left: 4px;
}

.funkoInformation .generalInfo .edition {
  background: #0353a8;
  padding: 4px;
  border-radius: 5px;
  color: #fff;
  margin-left: 4px;
}

.priceBox {
  display: flex;
}

.priceBox .priceNumber {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  text-align: center;
  margin-top: 0.5em;
}

.priceBox .priceNumber .priceLabel {
  align-self: end;
  margin-bottom: 10px;
}

.priceBox .priceNumber .avg .label {
  color: #17c2c0;
}

.priceBox .priceNumber .avg .number {
  background: #17c2c0;
  padding: 4px;
  border-radius: 5px;
  color: #fff;
}

.priceBox .priceNumber .min .label {
  color: #57b165;
}

.priceBox .priceNumber .min .number {
  background: #57b165;
  padding: 4px;
  border-radius: 5px;
  color: #fff;
}

.funkoDetail__icon-info {
  color: $blue;
}
