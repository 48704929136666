@import "./../commonStyles/variables.scss";
.home-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.home__carousel {
  margin-right: 13rem;
  margin-left: 13rem;
}

.delimiter {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}
.delimiterRounder {
  width: 100%;
  color: red;
  //margin-top: 12px;
}

.textBetweenDelimiter {
  color: #022342;
  text-align: center;
  width: 70%;
}
.textBetweenDelimiter span {
  font-size: 30px;
}
.vDivider {
  width: 80%;
  height: 7px;
  margin: 10px auto;
  background: #022342;
  border-bottom: 1px solid black;
}

.home__video-container {
  margin-top: 4em;
  background-color: #f9f8f4;
}

.titleHeader {
  text-align: left;
  margin-left: 5em;
  color: #022342;
  @media (min-width: 400px) {
    margin: 1em;
  }
}
.titleHeader h1 {
  font-family: Bullate !important;
  font-size: 3rem;
}

.embeddedBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.imgEmbedded {
  margin-left: 40px;
  margin-right: 40px;
  @media (max-width: 900px) {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.home__video-icon-info {
  font-size: 2rem;
  color: $blue;
}

.slides {
  position: relative;
  .slick-prev,
  .slick-next {
    // background-color: $blue;
    border-radius: 50px;
    position: absolute;
    top: 50%;
  }
  .slick-prev {
    right: 100%;
  }
  .slick-next {
    left: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: $blue;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.slick-arrow {
  @media only screen and (max-width: 768px) {
    display: none !important;
  }

  // @media only screen and (max-width: 768px) {
  //   .slick-prev {
  //     right: 98% !important;
  //     left: -14px !important;
  //   }
  //   .slick-next {
  //     left: 98% !important;
  //   }
}
